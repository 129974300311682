import "core-js/stable"
import "regenerator-runtime/runtime"
import * as Sentry from '@sentry/browser';

Sentry.init({
  ignoreErrors: [
    "Transport destroyed",
    "Rendering cancelled",
    "Object expected",
    "Bad (uncompressed) XRef entry",
  ],
  dsn: 'https://c12428bb2cfc4788864e19687d47a610@sentry.pludoni.com/3',
  release: window.SENTRY_RELEASE_NAME,
  environment: 'production'
});
if (window.SENTRY_CURRENT_USER) {
  Sentry.setUser(window.SENTRY_CURRENT_USER);
}
Sentry.setTag("facility", 'js');
Object.entries(window.SENTRY_TAGS).forEach((k, v) => {
  Sentry.setTag(k, v);
})
window.Sentry = Sentry
